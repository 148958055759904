document.addEventListener('turbolinks:load', function() {
	TearSheet.init();
});

TearSheet = {
	init: function() {

		$input = $('#sku')

		$('#qty').on("change", function() {
			var quantity = $(this).val()
			if(quantity > 0) {
				$('#tearsheet_add').attr( "disabled", false )
			}
		});

		$("#tearsheet_add").on('click', function() {
			var sku = $('#sku').val().toLowerCase();
			var qty = $('#qty').val();
			$('#tearsheet_add').hide();
			$('#spinner-btn').show();
			$.ajax({
				type:'post', 
				url: '/line_item',
				data: { part_number: sku, quantity: qty },
			})
			.done(function(data) {

				if(data.response) {
					$('#cart-nav-subtotal').text(data.cart_subtotal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}))
					$('#free-ship').load(' #free-ship > *')
					$('#tearsheet').load(' #tearsheet > *', function() {
						$('#tearsheet_add').show();
						$('#spinner-btn').hide();
					});
					$('#qty').val('');
					$('#sku').val('');
					$('#tearsheet_add').attr('disabled', true)
					$('#errors').empty()
					
				}
				if(data.error)  {
					$('#errors').empty().append(`<p class="prod_error"> ${data.error} </p>`)	
					if(data.inv_error) {
						$('#errors').append(`<p class="inv_error"> ${data.inv_error} </p>`)	
					}
					$('#tearsheet_add').show().attr('disabled', true);
					$('#spinner-btn').hide();
				}
			});

		});

		$( '#tearsheet' ).on('click', '.tearsheet_delete', function() {
			var id = $(this).closest('tr').data('id')
			$spin = $(this).closest('tr').find('#tearsheet_delete_spinner')
			$(this).hide();
			$spin.show();
			$.ajax({
				type: 'put',
				url: '/line_item/' + id + '/change',
				dataType: 'json',
				data: {
					'item[quantity]': 0
				},
				beforeSend: function(xhr) {
				xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
			})
			.done(function(data) {
				$('#tearsheet').load(' #tearsheet > *' )
				if(data.cart_subtotal > 0) {
					$('#cart-nav-subtotal').text(data.cart_subtotal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}))
				} else {
					$('#cart-nav-subtotal').hide();
				}
				$('#free-ship').load(' #free-ship > *')
			})

		})

	}
}

