document.addEventListener('turbolinks:load', function() {
	ShopView.init();
	ProductForm.init();
	Cart.init();
	Product.init();
	Nav.init();
	Inventory.init();
	ProductPricing.init();

	$('[data-toggle="tooltip"]').tooltip();

	$('.mobile-menu-toggle').on('click', function(e) {
		$('.mobile-nav').toggleClass('mobile-on');
		$('.mobile-menu-toggle').toggleClass('mobile-on');
	});

	$(document).ready(function() {
		$('.store-nav.sub-nav').each(function() {
			if($(this).attr("href") === window.location.pathname) {
				$(this).addClass('selected')
			}
		})
		if(window.location.pathname === '/store') {
			$('#all').addClass('selected')
		}
		if(window.location.pathname === '/store') {
			var shown = sessionStorage.getItem('modalshown');
			if(shown != 'shown') {
				$('#promo-modal').modal('show')
				sessionStorage.setItem('modalshown', 'shown');
			}
		}

	})

});


var Reqs = {
	throttle: function(fn, threshhold, scope) {
		threshhold || (threshhold = 250);

		var last,
			deferTimer;

		return function () {
			var context = scope || this;

			var now = +new Date,
				args = arguments;

			if (last && now < last + threshhold) {
				// hold on to it
				clearTimeout(deferTimer);
				deferTimer = setTimeout(function () {
					last = now;
					fn.apply(context, args);
				}, threshhold);
			} else {
				last = now;
				fn.apply(context, args);
			}
		}
	},
};


Nav = {
	init: function() {
		$( ".nav-cross" ).hide();
		$( ".menu" ).hide();
		$( ".nav-hamburger" ).click(function() {
			$( ".menu" ).slideToggle( "slow", function() {
				$( ".nav-hamburger" ).hide();
				$( ".nav-cross" ).show();
			});
		});

		$( ".nav-cross" ).click(function() {
			$( ".menu" ).slideToggle( "slow", function() {
				$( ".nav-cross" ).hide();
				$( ".nav-hamburger" ).show();
			});
		});
		$( ".main-menu" ).hide();
		$( ".nav-item.main.dropdown" ).hover(function() {
			$( ".main-menu" ).show();
		})
		$( ".main-menu" ).mouseleave(function() {
			$( ".main-menu").hide();
		})
	}
}


ShopView = {
	init: function() {
		$('.size-select').change( function() {
			var form_block = $(this).closest('.productForm-block')

			let qty_input = form_block.find('.itemq')[0]
			let button = form_block.find('.js-productForm-submit')[0]

			var id = $(this).find('option:selected').val()
			var productForm = form_block.find(".sized-add-to-cart")
			var checkout = form_block.find(".item-in-cart")

			$.ajax({
				method: 'GET',
				url: '/store/product_size_finder',
				data: { id: id },
			})
			.done(function(response) {
				if(response.error) {
					window.alert(response.error)
				}
				else {
					if(response.has_item) {
						$(productForm).hide()
						$(checkout).show()
					}
					else {
						$(checkout).hide()
						$(productForm).show()
						
						// have to set the max vals to selected sprods available inventory 
						new_max = response.max_quant
						qty_input.max = new_max
						button.dataset.maxq = new_max
					}
				}
			})
		})

		// Setup listeners to add/subtract from the input
		var $body = $( 'body' );
		$body.off( 'click', '.js-qty__adjust-btn' );
		$body.on( 'click', '.js-qty__adjust-btn', function(e) {
			e.preventDefault();
			var $adjustBtn = $(this),
				$qtySelector = $(this).siblings( 'input[name="item[quantity]"]' ),
				$maxEl = $(this).closest( '.qty__adjust' ),
				$msg = $maxEl.siblings('.crtmsg');

			qty = validateQty( parseInt($qtySelector.val().replace(/\D/g, '') ));
			max = validateQty( parseInt($maxEl.attr('data-inventory')), 0);

			// Add or subtract from the current quantity
			if ( $adjustBtn.hasClass( 'increase' )) {
				if (max == 0 || qty < max) {
					qty += 1;
					$msg.hide();
				} else {
					$msg.text('The maximum quantity available to order is ' + max);
					$msg.show();
				}
			} else {
				qty -= 1;
				if (qty < 1) qty = 1;
				$msg.hide();
			}

			// Update the input's number
			$qtySelector.val(qty);
		});

		// Prevent 0 quantity
		$body.off( 'focusout', '.qty__adjust > input' );
		$body.on( 'focusout', '.qty__adjust > input', function(e) {
			var currentValue = $(this).val();
			if ( !currentValue ) $(this).prop( 'value', 1 );
		} );

		$body.off( 'keydown', '.qty__adjust > input' );
		$body.on( 'keydown', '.qty__adjust > input', function(e) {
			var currentValue = $(this).val();
			if ( e.keyCode === 13 && !currentValue ) $(this).prop( 'value', 1 );
		});

		function validateQty(qty, def) {
			if((parseFloat(qty) != parseInt(qty)) || isNaN(qty)) {
				if (isNaN(def)) {
					qty = 1;
				} else {
					qty = 0;
				}
			}
			return qty;
		};

		$(".product-single__thumbnail-img").click(function() {
			var newSrc = $(this).prop('src');
			var prodId = this.id
			changeBackgroundImg(newSrc, prodId);
		});

		function changeBackgroundImg(newSrc, prodId) {
			$("#" + prodId).css('background-image', 'url(' + newSrc + ')')
		};
	}
};


ProductForm = {
	init: function() {
		$('body').on('click', '.js-productForm-submit', function(e) {

			var $form = $(this).closest('form.productForm'),
				$item = $form.find('.itemq'),
				max = this.dataset.maxq;

			itemq = parseInt($item.val());
			maxq = parseInt(max);

			if (itemq > maxq) return;
		
			e.preventDefault();

			if ($form.find('[type="file"]').length) return;

			ProductForm.submit($(this));
		});
	},
	submit: function(el) {
		var $form = el.closest('form.productForm'),
			product_handle = el.attr("data-handle"),
			// variant_id = $form.find('[name="id"]').val(),
			form_array = $form.serializeArray();
			$errors = $form.find('.errors')

		var form_data = {};

		$.map(form_array, function(val, i){
		  form_data[val.name] = val.value;
		});

		$form[0].reset();

		$.ajax({
			method: 'POST',
			url: '/line_item',
			dataType: 'json',
			data: form_data,
		})
		.done(function(response) {
			if(response.error) {
				$errors.empty().append(`<p class="prod_error"> ${response.error} </p>`)	
			}
			else {
				 let sp_id = response.sprod;
				 // let link = '/shipping';

				 $('#form-for' + sp_id).html(
				 	'<div class="d-flex justify-content-end align-items-center item-in-cart" style="margin-bottom:0; margin-top:auto"></div>'
				 	).append(

				 	'<a href="/shipping" class="btn btn-sm checkout" style="width:100%">"Item in Cart - CHECKOUT"</a>'
				 	);

				 	if(response.cart_subtotal > 0) {
 						$('#cart-nav-subtotal').text(response.cart_subtotal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}))
     				} else {
   						$('#cart-nav-subtotal').hide();
 					}


				// app would be faster if we just reload current prod card...or even just the form
				// location.reload();

			}
		})
	},
};

Cart = {
	init: function(){
		var self = this,
			$cart = $('#Cart');

		$cart.on( 'blur', '.inputCounter', function() {
			var el = $(this),
				value = $(this).val();

			self.inputCounter( el, value );
		});

		$cart.on('click', '.inputCounter-down', function(){
			var el = $(this),
				$input = el.siblings( '.inputCounter' ),
				value = parseInt( $input.val() ) - 1;

			self.inputCounter(el, value);
		}).on('click', '.inputCounter-up', function(){
			var el = $(this),
				$input = el.siblings( '.inputCounter' ),
				value = parseInt( $input.val() ) + 1;

			self.inputCounter(el, value);
		});

		/* Remove line item on x click */
		$cart.on( 'click', '.cart-product-remove', function() {
			var el = $(this);
			self.inputCounter(el, 0);
		});
	},

	inputCounter: function( el, value ) {
		var self = $(this),
			$cart = $( '#Cart' ),
			$input = el.closest( '.line-item' ).find( '.inputCounter' ),
			$msg = el.closest('.line-item').find('.crtmsg');

		prev = parseInt($input.val());
		max = parseInt($input.attr('data-inventory'));

		// Prevent a negative quantity
		if (value < 0) {
			value = 0;
		}

		// no value > max from inventory
		if (value > max) {
			$msg.text('Available Inventory is ' + max);
			$msg.show();
			value = prev;
		} 
		else {
			$msg.hide();
		}

		var qty = value;
		var id = $input.attr( 'id' );
		var line_total = el.closest( '.line-item' ).find( '.cart-product-total-price' );
		var cart_total = 0;
		$.ajax( {
			async: 'false',
			type: 'put',
			url: '/line_item/' + id + '/change',
			dataType: 'json',
			data: {
				'item[quantity]': qty
			},
			beforeSend: function(xhr) {
				xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		}).done(function(data) {
			$.each( data, function( key, val ) {
				if(key == 'cart_subtotal') {
					$('#cart-total-display').text(val.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}));
					$('#cart-nav-subtotal' ).text(val.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}));
				}
				if(key == 'line_item_total') {
					$(line_total).text(val.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}));
				}
				if(key == 'cart_quantity_total') {
					cart_total = val;
					if(val > 0) {
						$( '#cartTotal' ).text(val)
					} else {
						// send user back to store if cart is empty
						location.href = "/"
					}
				}
				$('#free-ship').load(' #free-ship > *')
			});			
		});

		// remove line item if the quantity is 0
		if ( qty == 0 ) {
			$input.closest( '.line-item' ).fadeOut( function() {
				$( this ).remove();
			} );
		} else {
			$input.val(qty);
		}
	},
};

Inventory = {
	init: function() {
		$('.sort').on('click', function() {
			var col = $(this).attr('data-id')
			$sort = $(this).find('svg')
			$spin = $(this).find('.spin')
			$sort.hide(function() {
				$spin.show(function() {
					Inventory.sortTable(col)
				})
			})
		})

		$('.sort-alpha').on('click', function() {
			console.log('click')
			var col = $(this).attr('data-id')
			$sort = $(this).find('svg')
			$spin = $(this).find('.spin')
			$sort.hide(function() {
				$spin.show(function() {
					Inventory.sortTableAlpha(col)
				})
			})
		})
	},

	sortTable: function(col) {
		var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  		table = document.getElementById("inv-sprods");

  		switching = true;
	  	// Set the sorting direction to ascending:
	  	dir = "asc";
	  	/* Make a loop that will continue until
	  	no switching has been done: */
	  	while (switching) {
	    	// Start by saying: no switching is done:
	    	switching = false;
	    	rows = table.rows;
	    	/* Loop through all table rows (except the
	    	first, which contains table headers): */
	    	for (i = 1; i < (rows.length - 1); i++) {
	     	 	// Start by saying there should be no switching:
	      		shouldSwitch = false;
	      		/* Get the two elements you want to compare,
	      		one from current row and one from the next: */
	     		x = rows[i].getElementsByTagName("TD")[col];
	     		y = rows[i + 1].getElementsByTagName("TD")[col];
	      		/* Check if the two rows should switch place,
	     		 based on the direction, asc or desc: */
	      		if (dir == "asc") {
	       			if (Number(x.innerHTML) > Number(y.innerHTML)) {
	  					shouldSwitch = true;
	  					break;
					}
	      		} else if (dir == "desc") {
	        		if (Number(x.innerHTML) < Number(y.innerHTML)) {
	          			// If so, mark as a switch and break the loop:
	          			shouldSwitch = true;
	          			break;
	       			}
	      		}
	    	}
	    	if (shouldSwitch) {
		      	/* If a switch has been marked, make the switch
		      	and mark that a switch has been done: */
		      	rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
		      	switching = true;
		      	// Each time a switch is done, increase this count by 1:
		      	switchcount ++;
	    	} else {
	     		/* If no switching has been done AND the direction is "asc",
	      		set the direction to "desc" and run the while loop again. */
	      		if (switchcount == 0 && dir == "asc") {
	        		dir = "desc";
	        		switching = true;
	     		}
	    	}
	  	}
	  	Inventory.resetSortButton()
	},

	sortTableAlpha: function(col) {
		var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
  		table = document.getElementById("inv-sprods");

  		switching = true;
	  	// Set the sorting direction to ascending:
	  	dir = "asc";
	  	/* Make a loop that will continue until
	  	no switching has been done: */
	  	while (switching) {
	    	// Start by saying: no switching is done:
	    	switching = false;
	    	rows = table.rows;
	    	/* Loop through all table rows (except the
	    	first, which contains table headers): */
	    	for (i = 1; i < (rows.length - 1); i++) {
	     	 	// Start by saying there should be no switching:
	      		shouldSwitch = false;
	      		/* Get the two elements you want to compare,
	      		one from current row and one from the next: */
	     		x = rows[i].getElementsByTagName("TD")[col];
	     		y = rows[i + 1].getElementsByTagName("TD")[col];
	      		/* Check if the two rows should switch place,
	     		 based on the direction, asc or desc: */
	      		if (dir == "asc") {
	       			if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
	  					shouldSwitch = true;
	  					break;
					}
	      		} else if (dir == "desc") {
	        		if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
	          			// If so, mark as a switch and break the loop:
	          			shouldSwitch = true;
	          			break;
	       			}
	      		}
	    	}
	    	if (shouldSwitch) {
		      	/* If a switch has been marked, make the switch
		      	and mark that a switch has been done: */
		      	rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
		      	switching = true;
		      	// Each time a switch is done, increase this count by 1:
		      	switchcount ++;
	    	} else {
	     		/* If no switching has been done AND the direction is "asc",
	      		set the direction to "desc" and run the while loop again. */
	      		if (switchcount == 0 && dir == "asc") {
	        		dir = "desc";
	        		switching = true;
	     		}
	    	}
	  	}
	  	Inventory.resetSortButton() 
	},

	resetSortButton: function() {
		$spin.hide()
		$sort.show()
	}
}


var Product = {
	selectors: {
		productSection: '[data-section-type="product"]',
		productThumbnail: '.js-product-single__thumbnail',
		productImage: '.product-image',
		productLayoutScrollable: '.product-layout--scrollable',
		productScrollerNav: '.js-productImgScroller-nav',
		productMediaThumb: '.product-single__media-thumb',
	},


	init: function(){
		var self = this,
			// update = (typeof update !== 'undefined') ? update : false;
			update = true;

		$( this.selectors.productSection ).each( function () {
			var container = $(this);

			self.initScroller( container, update );
		});
	},

	initScroller: function( container, update ) {
		var self = this,
			productImages = $( self.selectors.productImage, container ).length;

		if (productImages > 1) {
			var isPageLoaded = false,
				$productLayoutScrollable = $( self.selectors.productLayoutScrollable, container ),
				$productImg = $( self.selectors.productImage, container ),
				$productImgNav = $( self.selectors.productScrollerNav, container );

			// Adjust thumbnails position if change setting in TE
			if ( update ) stickyThumbnails();

			$(window)
				.on( 'scroll.product', Reqs.throttle( stickyThumbnails, 10 ) )
				.on( 'load', function() {
					isPageLoaded = true;
				});

			// Bind product thumb image click to scroll function
			$productImgNav.on( 'click', self.selectors.productThumbnail, function(e) {
				e.preventDefault();

				var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

				// if (windowWidth >= 768) {
					var id = $(this).parent().data( 'id' ),
						$selectedImage = $( self.selectors.productImage + '[data-id="' + id + '"]'),
						headerHeight = 59,
						additionalSpace = 40;

					if ( $selectedImage.length ) {
						var mediaId = $selectedImage.data( 'media-id' );
						if ( isPageLoaded || update ) {
							$('html, body')
								.stop( true, false )
								.animate({ scrollTop: $selectedImage.offset().top - headerHeight - additionalSpace }, 500, function() {
									// Product.switchMedia( container, mediaId )
								});
						}
					}
				// }
			});

			function stickyThumbnails() {
				var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

				if (windowWidth >= 768) {
					var scrolled = $(window).scrollTop(),
						productOffset = $productLayoutScrollable.offset().top - 100;

					for ( var i = $productImg.length - 1; i >= 0; i--) {
						var $currentProduct = $( self.selectors.productImage ).eq( i ),
							id = $currentProduct.data( 'id' ),
							productOffset = parseInt( $currentProduct.offset().top - 100 );

						if (scrolled >= productOffset) {
							$productImgNav.find( self.selectors.productMediaThumb + '[data-id="' + id + '"]').addClass( 'active' ).siblings().removeClass( 'active' );
							break;
						}
					}
				}
			}
		}
	}
};

ProductPricing = {
	init: function() {
		$('#prtsrch-btn').on('click', function() {
			var val = $('#prtsrch').val(),
				pd;

			// if the entered part exists copy it to the display spot
			pp = $('.data-val[data-find="' + val + '"]');
			pd = $('.data-val[data-price="' + val + '"]');

			$('#prtsrch-part').text(val);

			if (pp.text()) {
				$('#prtsrch-price').text(pd.text());
			}
			else {
				$('#prtsrch-price').text('Not Found');
			}
		})
	}
};
