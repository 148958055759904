document.addEventListener('turbolinks:load', function() {
	StorePromotion.init();
});

StorePromotion = {
	init: function() {

		let timeout = null
	        
		$( 'body' ).on( 'input', '.prod_part_num', function() {
	        let spinner = $(this).closest('.row').find('.spinner-border')
	        let desc = $(this).closest('.row').find('.prod_name')

	        let ppo_id = $(this).closest('.nested-fields').find('input[type=hidden]')[0]

	        $(spinner).show();
			
			clearTimeout(timeout);
			timeout = setTimeout(() => {
	            var sku = this.value;
				$.ajax({
					type:'post', 
					url: '/store_promotion/find_product',
					data: { part_number: sku },
				})
				.done(function(data) {
					if(data.response) {	
						$(desc).val(data.name);
						$(ppo_id).val(data.sp_id);
						$('#prod_errors').empty()
					}
					if(data.error)  {
						console.log(data.error)
					 	$('#prod_errors').empty().append(`<p class="prod_error"> ${data.error} </p>`)	
					}
					$(spinner).hide()
				});
			}, 1000)

		});

		$( 'body' ).on( 'change', '.promo-date', function() {
	            var date = this.value;

	            // need a way to exclude promotion being edited from date check
	            var prom_id = this.dataset.id

	            let input = $(this)
				$.ajax({
					type:'post', 
					url: '/store_promotion/check_date',
					data: { date: date, id: prom_id },
				})
				.done(function(data) {
					if(data.response) {	
						$('#date-errors').empty()
					}
					if(data.error)  {
					 	$('#date-errors').empty().append(`<p class="date-error"> ${data.error} </p>`);
					 	input.val('')	
					}
				});
		});

		$('.promo-details').on('show.bs.collapse', function () {
			// let parent = $(this).closest('.promo-container')
    		// $('html, body').animate({
       		// 	scrollTop: $(parent).offset().top 
    		// });
    		$('.collapse').collapse('hide');
		});

	}
	
}
